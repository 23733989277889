import { Component } from '@angular/core';
import {
  BimService,
  BuildingInformationModelListDto,
  BuildingSortColumn,
  SortOrder,
} from '@api-clients/bim';
import { Router } from '@angular/router';
import { AddressableUnitDto, BuildingsService } from '@api-clients/real-estate';
import { BimSource } from '@api-clients/bim/model/bimSource';
import { BimStatus } from '@api-clients/bim/model/bimStatus';
import { isRealEstateBuildingIdBimSubject } from '../../utils';

interface BuildingInformationModelListExtendedDto extends BuildingInformationModelListDto {
  address?: AddressableUnitDto;
}

@Component({
  selector: 'app-bim-overview',
  templateUrl: './bim-overview.component.html',
  styleUrl: './bim-overview.component.scss',
})
export class BimOverviewComponent {
  defaultPageSize: number = 10;
  sortBy: BuildingSortColumn = BuildingSortColumn.UploadTime;
  sortOrder: SortOrder = SortOrder.Asc;
  searchText: string = '';
  filteredData: BuildingInformationModelListExtendedDto[] = [];
  totalCount: number = 0;
  page: number = 1;
  pageSize: number = this.defaultPageSize;

  selectedBimSource?: BimSource;
  selectedBimStatus?: BimStatus;

  breadcrumbTree = [
    {
      translate: 'bim-overview-page',
      link: '/buildings',
    },
  ];

  // column data is added in html through a loop.
  columns = [
    { key: undefined, label: 'address' },
    { key: BuildingSortColumn.Description, label: 'description' },
    { key: BuildingSortColumn.Status, label: 'status' },
    { key: BuildingSortColumn.Source, label: 'source' },
    { key: BuildingSortColumn.UploadTime, label: 'upload-time' },
  ];

  constructor(
    private readonly bimService: BimService,
    private readonly router: Router,
    private readonly buildingService: BuildingsService
  ) {
    this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
    this.page = 1;
    this.pageSize = this.defaultPageSize;
    this.load();
  }

  load(): void {
    this.bimService
      .bimGet(
        this.page - 1,
        this.pageSize,
        this.sortBy,
        this.sortOrder,
        this.selectedBimSource,
        this.selectedBimStatus,
        this.searchText
      )
      .subscribe((data) => {
        this.filteredData = data.items.map((item) => <BuildingInformationModelListExtendedDto>item);
        this.totalCount = data.count;

        this.buildingService
          .includingAddressesGet(
            this.filteredData
              .filter((item) => item.subject && isRealEstateBuildingIdBimSubject(item.subject))
              .map((item) => item.subject!.id)
          )
          .subscribe((buildings) => {
            for (const building of buildings) {
              const item = this.filteredData.find(
                (item) =>
                  item.subject &&
                  isRealEstateBuildingIdBimSubject(item.subject) &&
                  item.subject.id === building.id
              );
              if (item) {
                item.address = building.addresses[0];
              }
            }
          });
      });
  }

  onPageChange(event: { page: number; pageSize: number }): void {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.load();
  }

  toggleSort(property: BuildingSortColumn | undefined): void {
    if (this.sortBy === property) {
      this.sortOrder = this.sortOrder == SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property ?? BuildingSortColumn.UploadTime;
      this.sortOrder = SortOrder.Asc;
    }
    this.load();
  }

  openDetail(item: BuildingInformationModelListExtendedDto): void {
    void this.router.navigate(['bim', item.id, 'model', 'overview']);
  }

  search(): void {
    this.load();
  }

  protected readonly BimSource = BimSource;
  protected readonly BimStatus = BimStatus;
  protected readonly SortOrder = SortOrder;
  protected readonly isReaEstateBuildingIdSubject = isRealEstateBuildingIdBimSubject;
}
