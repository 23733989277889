<div class="list-view">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

      <h2 class="h5">{{ 'residentialPortfolio' | translate }}</h2>
      <p>{{ buildings.length }} {{ 'unitsFound' | translate }}</p>
    </div>

    <div class="header-bar__search">
      <input
        class="top-search"
        type="search"
        [(ngModel)]="searchText"
        (input)="search()"
        placeholder="{{ 'search' | translate }}"/>
    </div>
  </div>

  <app-table-filter></app-table-filter>

  <table class="table responsive-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th (click)="toggleSort(column.key)" *ngIf="column.key !== undefined" class="sortable">
            {{ column.label | translate }}
            <span
              [class.arrow-active]="sortBy === column.key"
              [class.arrow-down]="sortOrder === SortOrder.Asc"
              class="arrow"></span>
          </th>
          <th *ngIf="column.key === undefined">
            {{ column.label | translate }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let building of filteredBuildings"
        [routerLink]="['/buildings/' + building.real_estate_id + '/dossier']">
        <ng-container *ngIf="building.buildingMetadata as metadata">
          <td [attr.data-title]="'Name' | translate">
            {{ building.buildingMetadata.name }}
          </td>
          <td [attr.data-title]="'Address' | translate">{{ metadata.address }}</td>
          <td [attr.data-title]="'Zipcode' | translate">{{ metadata.postalCode }}</td>
          <td [attr.data-title]="'City' | translate">{{ metadata.city }}</td>
        </ng-container>
        <td [attr.data-title]="'Kadaster id' | translate">
          {{ building.external_id }}
        </td>
        <td [attr.data-title]="'3D model' | translate">
          <button
            *ngIf="building.real_estate_id"
            (click)="navigateToModel($event, building.real_estate_id)"
            class="material-symbols-rounded model-link">
            home
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <app-table-pagination
    (pageChange)="onPageChange($event)"
    [total]="totalCount"
    [pageSize]="defaultPageSize"
    [page]="1"></app-table-pagination>
</div>
