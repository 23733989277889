/**
 * Rooftop estimation API
 * This API is used to estimate area of solar panels and empty area on rooftops.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SolarPanelCoverage { 
    estimated_empty_rooftop_space: number;
    current_coverage_of_solar_panels: number;
}

