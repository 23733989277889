/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GenericSubject } from './genericSubject';
import { InspectionContent } from './inspectionContent';


export interface Inspection { 
    id: string;
    organization_id: string;
    subject?: GenericSubject;
    user_id: string;
    dossier_id?: string;
    timestamp_utc: string;
    description: string;
    content: InspectionContent;
    finished_time_utc?: string;
}

