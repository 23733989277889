import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, map, ReplaySubject } from 'rxjs';
import { DossierService } from '@services/dossier.service';
import { ActivatedRoute } from '@angular/router';
import { Dossier } from '@api-clients/dossier';

@Injectable({
  providedIn: 'root',
})
export class DossierDetailService {
  dossier = new ReplaySubject<Dossier>(1);

  constructor(activatedRoute: ActivatedRoute, dossierService: DossierService) {
    activatedRoute.paramMap
      .pipe(
        takeUntilDestroyed(),
        map((paramMap) => paramMap.get('real_estate_building_id')),
        distinctUntilChanged()
      )
      .subscribe((buildingId) => {
        if (!buildingId) throw new Error('No real_estate_building_id found in route.');
        dossierService.getDossier(buildingId).then((dossier) => {
          this.dossier.next(dossier); // Return Observable
        });
      });
  }
}
