import { Injectable } from '@angular/core';
import { TimelineDataSource } from '../components/dossier-timeline/timeline-data-source';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { FilterModel } from '../components/dossier-timeline/FilterModel';
import { DossierService } from '@services/dossier.service';
import { UsersInfoService } from '@api-clients/user';
import { EnrichedTimeLineDto } from '../views/dossier-detail/EnrichedTimeLineDto';
import { Dossier, GenericSubject, TimeLineType } from '@api-clients/dossier';
import { DossierDetailService } from './dossier-detail.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class TimelineService {
  ds?: TimelineDataSource;
  dossier?: Dossier;
  private filterModel$ = new BehaviorSubject<FilterModel>({});
  public filterModel = this.filterModel$.asObservable();

  constructor(
    private readonly dossierService: DossierService,
    private readonly usersInfoService: UsersInfoService,
    private readonly dossierDetailService: DossierDetailService
  ) {
    this.dossierDetailService.dossier.pipe(takeUntilDestroyed()).subscribe((dossier) => {
      this.dossier = dossier;
      this.resetTimeline();
    });
  }

  public resetTimeline(): void {
    this.setFilterModel({});
  }

  public refreshTimeline(): void {
    this.setFilterModel(this.filterModel$.value);
  }

  public setFilterModel(filterModel: FilterModel): void {
    this.filterModel$.next(filterModel);
    if (!this.dossier) {
      throw new Error('No dossier');
    }
    this.ds = new TimelineDataSource(
      this.dossier,
      this.dossierService,
      this.usersInfoService,
      filterModel
    );
  }

  public updateFilterModel(filterModel: FilterModel): void {
    const newFilterModel = {
      ...this.filterModel$.value,
      ...filterModel,
    };

    this.setFilterModel(newFilterModel);
  }

  async publishNoteEvent(
    note: string,
    imageIds: string[] = [],
    locationId?: string,
    subject?: GenericSubject
  ): Promise<void> {
    if (!this.dossier && !subject) return;
    const dossierId = subject ? undefined : this.dossier?.id;
    const newEvent = await this.dossierService.postNoteEvent(dossierId, {
      subject,
      rich_text: note,
      image_ids: imageIds,
      location_id: locationId,
    });
    const users = await lastValueFrom(this.usersInfoService.get([newEvent.user_id]));

    const enrichedEvent: EnrichedTimeLineDto = {
      ...newEvent,
      dossier_id: newEvent.dossier_id ?? '', // TODO : this is not how it should be done. Best is to create a note-component without dossier_id
      description: note,
      userName: users[newEvent.user_id],
      item_id: newEvent.id,
      item_type: TimeLineType.Note,
    };

    this.ds?.addEvent(enrichedEvent);
  }

  async publishIfcEvent(description: string, linked_bim_id: string): Promise<void> {
    if (!this.dossier) return;
    const newEvent = await this.dossierService.postIfcEvent(this.dossier.id, {
      description,
      linked_bim_id,
    });
    const users = await lastValueFrom(this.usersInfoService.get([newEvent.user_id]));
    const enrichedEvent: EnrichedTimeLineDto = {
      ...newEvent,
      timestamp_utc: Date(),
      userName: users[newEvent.user_id],
      item_id: newEvent.id,
      item_type: TimeLineType.BimLink,
    };

    this.ds?.addEvent(enrichedEvent);
  }
}
