/**
 * Real Estate API
 * This API is used to maintain buildings and their addresses.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddressableUnitModel { 
    id: string;
    building_id: string;
    address: string;
    zip_code: string;
    house_number: string;
    place: string;
}

