<div oncontextmenu="return false;">
  <ul
    *ngIf="context"
    [class.active]="context.active"
    [class.animate-position]="context.animate"
    [style.left.px]="context.x"
    [style.top.px]="context.y"
    class="context-menu">
    <li *ngFor="let item of this.context.list | visible">
      <a
        tabindex="-1"
        (keydown)="keyDown($event)"
        (click)="item.action(); close()"
        *ngIf="item.action && item.checked === undefined"
        click-stop-propagation>
        <span *ngIf="item?.icon" class="material-symbols-rounded">
          {{ item.icon }}
        </span>
        {{ item.name || '' | translate: params(item) }}
      </a>

      <a
        class="checkboxItem"
        *ngIf="item.action !== undefined && item.checked !== undefined"
        click-stop-propagation>
        <label class="checkboxcontainer">
          {{ item.name || '' | translate: params(item) }}

          <input [checked]="item.checked!()" (change)="item.action!()" type="checkbox"/>
          <span class="checkmark"></span>
        </label>
      </a>

      <a
        (click)="close()"
        *ngIf="item.link && !item.action"
        routerLink="{{ item.link }}">
        <span *ngIf="item.icon" class="material-symbols-rounded">
          {{ item.icon }}
        </span>
        {{ item.name || '' | translate: params(item) }}
      </a>

      <div *ngIf="!item.action && item.divider" class="divider" click-stop-propagation></div>
    </li>
  </ul>
</div>
