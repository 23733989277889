import { EventEmitter, Injectable } from '@angular/core';
import { InspectionTemplate } from '@api-clients/dossier';

@Injectable({
  providedIn: 'root',
})
export class ChooseTemplatePopupService {
  public hidden: EventEmitter<boolean> = new EventEmitter<boolean>();
  public shown: EventEmitter<boolean> = new EventEmitter<boolean>();
  public copyTemplate: EventEmitter<InspectionTemplate> = new EventEmitter<InspectionTemplate>();

  constructor() {}

  public hide(): void {
    this.hidden.emit(true);
  }

  public show(): void {
    this.shown.emit(true);
  }

  public templateSelected(template: InspectionTemplate): void {
    this.copyTemplate.emit(template);
  }
}
