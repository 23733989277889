<div class="choose-template-popup" [class.active]="active">
  <div class="choose-template-popup__header">
    <h4>{{ 'choose-template' | translate }}</h4>
    <button class="close-button" (click)="close()">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>
  <div class="choose-template-popup__content">
    <div class="choose-template-popup__content__container big-input">
      <label for="template">
        {{ 'choose-your-template' | translate }}
      </label>
      <select id="template" [(ngModel)]="selectedTemplateId">
        <option [value]="template.id" *ngFor="let template of inspectionTemplateList">{{template.description}}</option>
      </select>
    </div>

    <div class="choose-template-popup__content__buttons">
      <button (click)="close()" class="cancel-button">{{ 'cancel' | translate }}</button>
      <button (click)="copy()" class="button-primary" [disabled]="selectedTemplateId === undefined">{{ 'copy' | translate }}</button>
    </div>
  </div>
</div>

<button
  class="choose-template-popup__background"
  (click)="close()"
  [class.active]="active">&nbsp;</button>
