<div class="user-detail-page">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

      <h1 class="h4">{{ 'user-detail-page' | translate }}</h1>
    </div>
  </div>

  <form [formGroup]="userDetailForm" (submit)="updateUser()" class="form">
    <div class="form-group">
      <app-profile-picture></app-profile-picture>
    </div>

    <div class="form-group half">
      <label for="first-name">{{ 'first-name' | translate }}</label>
      <input id="first-name" type="text" formControlName="firstName" />
      <div class="error half" *ngIf="userDetailForm.controls.firstName.errors?.['required']">
        {{ 'first-name-required-error' | translate }}
      </div>
    </div>

    <div class="form-group half">
      <label for="last-name">{{ 'last-name' | translate }}</label>
      <input id="last-name" class="half" type="text" formControlName="lastName" />
      <div class="error" *ngIf="userDetailForm.controls.lastName.errors?.['required']">
        {{ 'last-name-required-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="email">{{ 'email-address' | translate }}</label>
      <input id="email" class="input" type="text" formControlName="email" />
      <div class="error" *ngIf="userDetailForm.controls.email.errors?.['required']">
        {{ 'email-required-error' | translate }}
      </div>
      <div class="error" *ngIf="userDetailForm.controls.email.errors?.['email']">
        {{ 'email-invalid-error' | translate }}
      </div>
    </div>

    <div class="form-group" *ngIf="canSetOrganization">
      <label for="organization">{{ 'organization' | translate }}</label>
      <select formControlName="organizationId" id="organization">
        <option *ngFor="let organization of organizations" [value]="organization.id">
          {{ organization.name }}
        </option>
      </select>
    </div>


    <div class="form-group">
      <app-lightswitch
        [label]="'address' | translate"
        [checked]="includeAddress"
        (changedState)="setAddress(!includeAddress)"></app-lightswitch>
    </div>

    <app-address-detail formGroupName="address" *ngIf="includeAddress">
    </app-address-detail>

    <div class="form-group">
      <label for="organizationRole">{{ 'role' | translate }}</label>
      <select formControlName="organizationRole" id="organizationRole">
        <option [ngValue]="OrganizationRole.User">{{ 'user' | translate }}</option>
        <option [ngValue]="OrganizationRole.Administrator">
          {{ 'administrator' | translate }}
        </option>
      </select>
    </div>

    <button type="submit" class="button-primary button--has-icon" [disabled]="isSaving">
      <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
      {{ 'user-save' | translate }}
    </button>
    <button
      type="button"
      *ngIf="canDelete && !isNew"
      (click)="openRemovePopup()"
      class="button-remove">
      {{ 'user-remove' | translate }}
    </button>
  </form>

  <app-confirmation-dialog
    [title]="'user-delete-title' | translate"
    [description]="'user-delete-description' | translate"
    [icon]="'bin'"
    [confirmText]="'user-delete-confirm' | translate"
    (confirmOutput)="removeUser()"></app-confirmation-dialog>
</div>
