<div class="images-question" [class.edit]="edit" [contextMenu]="menu" [contextMenuDisabled]="!edit">
  <label for="input" class="images-question__title">
    {{ edit ? ('images-question' | translate) : question.description }}
    <span class="subject" *ngIf="!edit && question.subject.title">
      ({{ question.subject.title }})
    </span>
  </label>

  <div class="images-question__wrapper">
    <div class="images-question__input big-input">
      <input
        placeholder="{{ 'question-name' | translate }}"
        type="text"
        name="input"
        id="input"
        autocomplete="off"
        [(ngModel)]="question.description"
        *ngIf="edit" />

      <div class="images-question__additional_fields">
        <label for="subject" class="images-question__subject" *ngIf="edit">
          {{ 'subject' | translate }}
        </label>

        <input
          placeholder="{{ 'subject' | translate }}"
          type="text"
          name="input"
          id="subject"
          autocomplete="off"
          [(ngModel)]="question.subject.title"
          *ngIf="edit" />
      </div>

      <div
        class="images-question__answer"
        *ngIf="!edit && !isInspectionFinding(question)"
        [class.disabled]="disabled">
        <button class="button-primary button--has-icon">
          <span class="material-symbols-rounded">camera</span>
          {{ 'dossier-module.take-photo' | translate }}
        </button>

        <button class="button-secondary button--has-icon">
          <span class="material-symbols-rounded">photo</span>
          {{ 'dossier-module.add-photo' | translate }}
        </button>
      </div>
      <div class="images-question__answer" *ngIf="!edit && isInspectionFinding(question)">
        <div class="images-question__answer__images" *ngIf="inspection">
          <div
            class="image"
            tabindex="0"
            *ngFor="let img_id of image_ids">
            <img
              [src]="
                environment.API_BASE_PATH +
                  '/dossiers/image/' +
                  img_id
                  | secure
                  | async
              "
              alt="image" />
            <span class="material-symbols-rounded">zoom_in</span>
          </div>
        </div>
      </div>

      <input
        type="file"
        #imageInput
        accept="image/png, image/gif, image/jpeg"
        style="display: none"
        [disabled]="disabled"
        *ngIf="!edit"
        [class.disabled]="disabled"
        (change)="imageInputChanged()" />
      <button *ngIf="isInspectionFinding(question)" [class.disabled]="disabled" (click)="uploadImage()" class="button-secondary button--has-icon">
        <span class="material-symbols-rounded">add_a_photo</span>
        ({{ image_ids.length }})
      </button>
    </div>

    <app-three-dot [menu]="menu"></app-three-dot>
  </div>
</div>
