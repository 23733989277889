import { Component, HostListener, Input } from '@angular/core';
import { InspectionPopupService, InspectionStatus } from './inspection-popup.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  CreateInspectionRequest,
  GenericSubject,
  Inspection,
  InspectionsService,
  InspectionTemplate,
  InspectionTemplateList,
} from '@api-clients/dossier';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inspection-popup',
  templateUrl: './inspection-popup.component.html',
  styleUrl: './inspection-popup.component.scss',
})
export class InspectionPopupComponent {
  protected inspectionStatus: InspectionStatus;
  protected inspectionTemplateList: InspectionTemplateList[] = [];
  protected pageIndex: number = 0;
  protected selectedTemplateId? = '';
  protected inspection?: Inspection;

  @Input() skipPopup: boolean = false;
  @Input() dossierId?: string;
  @Input() buildingId?: string;

  constructor(
    private readonly inspectionPopupService: InspectionPopupService,
    private readonly inspectionsService: InspectionsService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {
    this.inspectionStatus = inspectionPopupService.inspectionStatus;
    inspectionPopupService.inspectionStatusChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.inspectionStatus = value;
    });
    inspectionPopupService.shown.subscribe(() => {
      this.inspectionsService.templateGet().subscribe((next) => {
        this.pageIndex = 0;
        this.inspectionTemplateList = next;
      });
    });
  }

  async save(): Promise<void> {
    if (this.selectedTemplateId === undefined || this.inspection === undefined) return;
    this.inspectionsService.inspectionPut(this.inspection).subscribe((next) => {
      this.inspection = next;
    });
    this.inspectionPopupService.hide();
  }

  async saveAndFinish(): Promise<void> {
    if (this.selectedTemplateId === undefined || this.inspection === undefined) return;
    this.inspectionsService.inspectionFinishPut(this.inspection).subscribe((next) => {
      this.inspection = next;
    });
    this.close();
  }

  close(): void {
    this.inspectionPopupService.hide();
  }

  navigateToTemplates(): void {
    this.pageIndex = 0;
    this.close();
    void this.router.navigate(['/inspections/templates']);
  }

  templateName(templateId: string | undefined): Observable<string> {
    const fallbackDescription = this.translateService.instant('new-inspection');
    if (!templateId) return of(fallbackDescription);

    return this.inspectionsService.templateIdGet(templateId).pipe(
      map((templateResponse: InspectionTemplate) =>
        templateResponse.description ? templateResponse.description : fallbackDescription
      ),
      catchError((error) => {
        console.error('Failed to fetch template description:', error);
        return of(fallbackDescription);
      })
    );
  }

  next(): void {
    let subject: GenericSubject | undefined = undefined;
    if (this.buildingId) {
      subject = {
        real_estate_building_id: this.buildingId,
        type: 'RealEstateBuilding',
      };
    }

    this.templateName(this.selectedTemplateId).subscribe((templateDescription) => {
      const createInspectionRequest: CreateInspectionRequest = {
        name: templateDescription,
        description: templateDescription,
        template_id: this.selectedTemplateId ? this.selectedTemplateId : undefined,
        subject: subject,
      };

      this.inspectionsService.inspectionPost(createInspectionRequest, this.dossierId).subscribe({
        next: (inspection) => {
          this.inspection = inspection;

          if (this.skipPopup || this.selectedTemplateId === undefined) {
            this.close();
            void this.router.navigate([this.inspection.id], {
              relativeTo: this.activatedRoute,
              queryParams: { edit: true },
            });
          } else {
            this.pageIndex = 1;
            this.inspectionPopupService.inspectionAdded.emit(true);
          }
        },
        error: (err) => {
          console.error('Failed to create inspection:', err);
        },
      });
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
    this.close();
  }
}
