import { Injectable } from '@angular/core';
import {
  Inspection,
  InspectionFinding,
  InspectionSection,
  InspectionTemplate,
  InspectionTemplateQuestion,
  InspectionTemplateSection,
} from '@api-clients/dossier';
import { isInspection, isInspectionSection } from '../../building-module/utils';
import { ChooseTemplatePopupService } from '../components/toolbox/choose-template/choose-template-popup.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  private inspectionOrTemplate?: InspectionTemplate | Inspection;
  private currentSection: InspectionTemplateSection | InspectionSection | undefined;

  constructor(private readonly chooseTemplatePopupService: ChooseTemplatePopupService) {
    this.chooseTemplatePopupService.copyTemplate.subscribe((template) => {
      if (!this.inspectionOrTemplate) return;
      if (isInspection(this.inspectionOrTemplate)) {
        for (const section of template.definition.sections) {
          const newSection = {
            name: section.name,
            findings: section.questions.map((question) => ({
              subject: {
                title: question.subject.title,
                type: question.subject.type,
              },
              property: question.property,
              description: question.description,
              value: {
                type: question.property.property_type,
                text: '',
                image_ids: [],
                values: [],
                value: '',
              },
            })),
          };
          this.inspectionOrTemplate.content.sections.push(newSection);
          this.setSection(newSection);
        }
      } else {
        for (const section of template.definition.sections) {
          this.inspectionOrTemplate.definition.sections.push(section);
        }
      }
    });
  }

  public setInspectionOrTemplate(inspectionOrTemplate: InspectionTemplate | Inspection): void {
    this.inspectionOrTemplate = inspectionOrTemplate;
  }

  public setSection(section: InspectionTemplateSection | InspectionSection): void {
    this.currentSection = section;
  }

  public getCurrentSection(): InspectionTemplateSection | InspectionSection | undefined {
    return this.currentSection;
  }

  questions(): InspectionFinding[] | InspectionTemplateQuestion[] | undefined {
    if (!this.currentSection) return;
    return isInspectionSection(this.currentSection)
      ? (this.currentSection as InspectionSection).findings
      : (this.currentSection as InspectionTemplateSection).questions;
  }

  async insertSection(): Promise<void> {
    if (!this.inspectionOrTemplate) return;

    const newSection = isInspection(this.inspectionOrTemplate)
      ? { name: '', findings: [] }
      : { name: '', questions: [] };

    if (isInspection(this.inspectionOrTemplate)) {
      this.inspectionOrTemplate.content.sections.push(newSection as InspectionSection);
    } else {
      this.inspectionOrTemplate.definition.sections.push(newSection as InspectionTemplateSection);
    }

    this.setSection(newSection as InspectionTemplateSection | InspectionSection);
  }

  async deleteSection(section: InspectionSection | InspectionTemplateSection): Promise<void> {
    if (!this.currentSection || !section || !this.inspectionOrTemplate) return;
    // Update view so that the section is removed from the view before the request is sent
    if (isInspection(this.inspectionOrTemplate)) {
      this.inspectionOrTemplate.content.sections =
        this.inspectionOrTemplate.content.sections.filter((s) => s !== section);

      this.currentSection = this.inspectionOrTemplate.content.sections
        ? this.inspectionOrTemplate.content.sections[0]
        : undefined;
    } else {
      this.inspectionOrTemplate.definition.sections =
        this.inspectionOrTemplate.definition.sections.filter((s) => s !== section);

      this.currentSection = this.inspectionOrTemplate.definition.sections
        ? this.inspectionOrTemplate.definition.sections[0]
        : undefined;
    }
  }

  insertCheckList(): void {
    if (!this.inspectionOrTemplate) return;
    const questions = this.questions();
    if (!questions) return;
    const question: InspectionFinding = {
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'CheckList',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
      value: {
        type: 'CheckList',
        values: [],
        image_ids: [],
      },
    };

    questions.push(question);
  }

  insertRadioList(): void {
    const questions = this.questions();
    if (!questions) return;
    const question: InspectionFinding = {
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'RadioList',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
      value: {
        type: 'RadioList',
        value: '',
        image_ids: [],
      },
    };
    questions.push(question);
  }

  insertOpenQuestion(): void {
    const questions = this.questions();
    if (!questions) return;
    const question: InspectionFinding = {
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'Text',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
      value: {
        type: 'Text',
        text: '',
      },
    };
    questions.push(question);
  }

  insertImageListQuestion(): void {
    const questions = this.questions();
    if (!questions) return;
    const question: InspectionFinding = {
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'ImageList',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
      value: {
        type: 'ImageList',
        image_ids: [],
      },
    };

    questions.push(question);
  }

  insertBooleanQuestion(): void {
    const questions = this.questions();
    if (!questions) return;
    const question: InspectionFinding = {
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'Boolean',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
      value: {
        type: 'Boolean',
        boolean: false,
      },
    };
    questions.push(question);
  }

  insertNumberQuestion(): void {
    const questions = this.questions();
    if (!questions) return;
    const question: InspectionFinding = {
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'Number',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
      value: {
        type: 'Number',
        number: 0,
      },
    };
    questions.push(question);
  }
}
