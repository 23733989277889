import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-bubble',
  standalone: true,
  imports: [],
  templateUrl: './info-bubble.component.html',
  styleUrl: './info-bubble.component.scss',
})
export class InfoBubbleComponent {
  @Input() message: string = '';
}
