import { Component } from '@angular/core';
import {
  InspectionSection,
  InspectionsService,
  InspectionTemplate,
  InspectionTemplateSection,
} from '@api-clients/dossier';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ToastrService } from '@shared/services/toastr.service';
import { InspectionService } from '../../services/inspection.service';

@Component({
  selector: 'app-inspection-template-detail',
  templateUrl: './inspection-template-detail.component.html',
  styleUrl: './inspection-template-detail.component.scss',
})
export class InspectionTemplateDetailComponent {
  protected inspectionTemplateId: string | undefined;
  protected edit = true;
  protected inspectionTemplate: InspectionTemplate;
  protected currentSection: InspectionTemplateSection | InspectionSection | undefined;
  protected isSaving: boolean = false;

  protected breadcrumbTree = [
    {
      translate: 'inspection-templates',
      link: '/inspections/templates',
    },
    {
      translate: 'edit',
      link: '/inspections/templates/{id}',
    },
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly inspectionsService: InspectionsService,
    private readonly userService: UserService,
    private readonly toastrService: ToastrService,
    protected readonly inspectionService: InspectionService
  ) {
    this.inspectionTemplate = {
      id: '',
      description: '',
      definition: {
        inspection_name: '',
        sections: [],
      },
      timestamp_utc: '',
      organization_id: this.userService.organizationId,
    };

    this.inspectionService.setInspectionOrTemplate(this.inspectionTemplate);

    this.inspectionTemplateId = route.snapshot.paramMap.get('id') ?? undefined;
    if (this.inspectionTemplateId === 'new') this.inspectionTemplateId = undefined; //ugly but necessary
    if (this.inspectionTemplateId) {
      this.inspectionsService.templateIdGet(this.inspectionTemplateId).subscribe((next) => {
        this.inspectionTemplate = next;
        this.inspectionService.setInspectionOrTemplate(next);
        this.updateBreadcrumb();

        if (this.inspectionTemplate.definition.sections) {
          this.inspectionService.setSection(this.inspectionTemplate.definition.sections[0]);
        }
      });
    } else {
      this.edit = true;
    }
  }

  changeSwitch(value: boolean): void {
    this.edit = !value;
  }

  updateBreadcrumb(): void {
    this.breadcrumbTree = [
      {
        translate: 'inspection-templates',
        link: '/inspections/templates',
      },
      {
        translate: 'edit',
        link: `/inspections/templates/${this.inspectionTemplateId}`,
      },
    ];
  }

  arrayMove(arr, fromIndex: number, toIndex: number): void {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  activateSection(section: InspectionTemplateSection): void {
    this.currentSection = section;
    this.inspectionService.setSection(section);
  }

  itemDropped(event: CdkDragDrop<InspectionTemplateSection>): void {
    if (event.previousIndex === event.currentIndex) return;
    this.arrayMove(
      this.inspectionTemplate.definition.sections,
      event.previousIndex,
      event.currentIndex
    );
  }

  deleteSection(section: InspectionTemplateSection): void {
    this.inspectionService.deleteSection(section);
  }

  save(): void {
    if (this.isSaving) return;
    this.isSaving = true;
    if (this.inspectionTemplateId === undefined) {
      // new template
      this.inspectionsService
        .templatePost({
          description: this.inspectionTemplate.description,
          definition: this.inspectionTemplate.definition,
        })
        .subscribe((next) => {
          this.isSaving = false;
          this.edit = false;
          this.toastrService.showSuccess(
            'dossier-module.template.save-success',
            'dossier-module.template.save-title'
          );
          void this.router.navigate(['..', next.id], {
            relativeTo: this.route,
          });
        });

      return;
    }

    // changed template
    this.inspectionsService
      .templateIdPut(this.inspectionTemplateId, {
        description: this.inspectionTemplate.description,
        definition: this.inspectionTemplate.definition,
      })
      .subscribe((next) => {
        this.inspectionTemplate = next;
        this.inspectionService.setInspectionOrTemplate(next);
        this.isSaving = false;
        this.edit = false;
        this.toastrService.showSuccess(
          'dossier-module.template.save-success',
          'dossier-module.template.save-title'
        );
      });

    this.edit = false;
  }
}
