/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptionalGenericSubjectQueryGenericSubjectBimElementReference { 
    optional_GenericSubject_bim_element_id: string;
    optional_GenericSubject_bim_id: string;
    optional_GenericSubject_type: OptionalGenericSubjectQueryGenericSubjectBimElementReference.OptionalGenericSubjectTypeEnum;
}
export namespace OptionalGenericSubjectQueryGenericSubjectBimElementReference {
    export type OptionalGenericSubjectTypeEnum = 'BimElement';
    export const OptionalGenericSubjectTypeEnum = {
        BimElement: 'BimElement' as OptionalGenericSubjectTypeEnum
    };
}


