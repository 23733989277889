<div class="table-filter">
<!--  <div class="table-filter__active">-->
<!--    &lt;!&ndash;    Dummy filtering uncomment to see&ndash;&gt;-->
<!--&lt;!&ndash;        <button (click)="removeFilter($event)">&ndash;&gt;-->
<!--&lt;!&ndash;          Appartement&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="material-symbols-rounded">close</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </button>&ndash;&gt;-->
<!--&lt;!&ndash;        <button (click)="removeFilter($event)">&ndash;&gt;-->
<!--&lt;!&ndash;          Boswinkel&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="material-symbols-rounded">close</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </button>&ndash;&gt;-->
<!--&lt;!&ndash;        <button (click)="removeFilter($event)">&ndash;&gt;-->
<!--&lt;!&ndash;          Enschede&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="material-symbols-rounded">close</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </button>&ndash;&gt;-->
<!--  </div>-->
  <!--    Ghost feature -->
  <ng-content></ng-content>
<!--    <div class="select">-->
<!--      <select>-->
<!--        <option value="Option 1">Filter on status</option>-->
<!--        <option value="Option 2">Option 2</option>-->
<!--        <option value="Option 3">Option 3</option>-->
<!--        <option value="Option 4">Option 4</option>-->
<!--        <option value="Option 5">Option 5</option>-->
<!--        <option value="Option length">Option that has too long of a value to fit</option>-->
<!--      </select>-->
<!--    </div>-->
</div>
