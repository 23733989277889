import { Component, ElementRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextMenuItem, ContextService } from '../../../services';

@Component({
  selector: 'app-three-dot',
  styleUrls: ['./three-dot.component.scss'],
  templateUrl: './three-dot.component.html',
})
export class ThreeDotComponent {
  @Input() menu: ContextMenuItem[] = [];
  public sub: Subscription = new Subscription();

  x: number = 0;
  y: number = 0;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    public contextService: ContextService
  ) {}

  get visibleItems(): ContextMenuItem[] {
    return this.menu.filter((item) =>
      typeof item.visible !== 'function' ? item.visible !== false : item.visible()
    );
  }

  public open(event$): void {
    const width = document.getElementsByClassName('context-menu')[0].clientWidth - 40;

    // DO NOT ALTER THE MENU HERE

    const itemHeight = 48 * this.menu.filter((item) => !item.divider).length;
    const dividerHeight = 17 * this.menu.filter((item) => item.divider).length;
    const height = itemHeight + 10 + dividerHeight;
    const screenTop = this.elementRef.nativeElement.getBoundingClientRect().top;

    if (screenTop + height > window.innerHeight) {
      this.y = window.innerHeight - height;
    } else {
      this.y = this.elementRef.nativeElement.getBoundingClientRect().top + 50;
    }

    this.x = this.elementRef.nativeElement.getBoundingClientRect().left;
    if (this.x + width > document.body.offsetWidth) {
      this.x = this.elementRef.nativeElement.getBoundingClientRect().left - width;
    }

    event$.stopPropagation();
    event$.preventDefault();
    //preventing a double contextService call
    setTimeout(() => {
      this.contextService.set(this.x, this.y, this.menu);
    }, 10);
    setTimeout(() => {
      this.contextService.animate(true);
    }, 300);
  }

  showContextMenu = (event$): void => {
    this.open(event$);
  };
}
