<div class="toolbox" [class.active]="edit" [class.no-section]="!currentSection">
  <div class="toolbox__wrapper">
    <button class="material-symbols-rounded toolbox-function" (click)="insertSection()">
      inbox
    </button>
    <button class="material-symbols-rounded toolbox-function" (click)="insertTemplate()">
      inbox_text
    </button>
    <div class="divider"></div>

    <button
      class="material-symbols-rounded toolbox-function section-item"
      (click)="insertRadioList()">
      radio_button_checked
    </button>
    <button
      class="material-symbols-rounded toolbox-function section-item"
      (click)="insertCheckList()">
      check_box
    </button>
    <button
      class="material-symbols-rounded toolbox-function section-item"
      (click)="insertOpenQuestion()">
      edit_square
    </button>
    <button
      class="material-symbols-rounded toolbox-function section-item"
      (click)="insertBooleanQuestion()">
      toggle_on
    </button>
    <button
      class="material-symbols-rounded toolbox-function section-item"
      (click)="insertNumberQuestion()">
      pin
    </button>
    <button
      class="material-symbols-rounded toolbox-function section-item"
      (click)="insertImageListQuestion()">
      photo
    </button>
  </div>
</div>

<app-choose-template-popup></app-choose-template-popup>
