/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GenericSubjectRealEstateBuildingReference { 
    real_estate_building_id: string;
    type: GenericSubjectRealEstateBuildingReference.TypeEnum;
}
export namespace GenericSubjectRealEstateBuildingReference {
    export type TypeEnum = 'RealEstateBuilding' | 'Bim' | 'BimElement';
    export const TypeEnum = {
        RealEstateBuilding: 'RealEstateBuilding' as TypeEnum,
        Bim: 'Bim' as TypeEnum,
        BimElement: 'BimElement' as TypeEnum
    };
}


