/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptionalGenericSubjectQueryGenericSubjectRealEstateBuildingReference { 
    optional_GenericSubject_real_estate_building_id: string;
    optional_GenericSubject_type: OptionalGenericSubjectQueryGenericSubjectRealEstateBuildingReference.OptionalGenericSubjectTypeEnum;
}
export namespace OptionalGenericSubjectQueryGenericSubjectRealEstateBuildingReference {
    export type OptionalGenericSubjectTypeEnum = 'RealEstateBuilding';
    export const OptionalGenericSubjectTypeEnum = {
        RealEstateBuilding: 'RealEstateBuilding' as OptionalGenericSubjectTypeEnum
    };
}


