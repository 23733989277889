import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
import {
  InspectionFinding,
  InspectionTemplateQuestion,
  InspectionValueValueNumber,
} from '@api-clients/dossier';

@Component({
  selector: 'app-inspection-number-question',
  templateUrl: './number-question.component.html',
  styleUrl: './number-question.component.scss',
})
export class NumberQuestionComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion | InspectionFinding;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Input() disabled = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  expanded = false;
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: (): boolean => !this.first,
        action: (): void => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: (): boolean => !this.last,
        action: (): void => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => this.delete.emit(),
      },
      {
        name: 'show-units',
        checked: (): boolean =>
          this.question.property.unit !== undefined && this.question.property.unit !== null,
        action: (): void => this.toggleUnits(),
      },
    ];
  }

  toggleUnits(): void {
    if (this.question.property.unit === undefined || this.question.property.unit === null) {
      this.question.property.unit = '';
    } else {
      this.question.property.unit = undefined;
    }
  }

  get value(): number {
    const finding = this.question as InspectionFinding;
    if (finding.value === null || finding.value === undefined) {
      return 0;
    }
    return (finding.value as InspectionValueValueNumber).number;
  }

  set value(newValue: number | string) {
    // Convert to a number or undefined
    const convertedValue = this.convertToNumber(newValue);

    // Update only if convertedValue is a valid number
    if (convertedValue !== undefined) {
      const finding = this.question as InspectionFinding;
      finding.value = { number: convertedValue, type: 'Number' };
    }
  }

  sanitizeInput(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    // Replace all commas with dots
    inputValue = inputValue.replace(/,/g, '.');

    // Preserve valid characters: digits, a single leading dash, and a single dot.
    inputValue = inputValue.replace(/[^0-9.-]/g, '');

    // Ensure there is only one dot, and it's retained at its original position.
    const dotIndex = inputValue.indexOf('.');
    if (dotIndex !== -1) {
      inputValue = inputValue.replace(/\./g, (match, offset) => (offset === dotIndex ? match : ''));
    }

    // Allow a single leading dash. Remove any additional dashes.
    if (inputValue.indexOf('-') > 0) {
      inputValue = inputValue.replace(/-/g, '');
    } else if (inputValue.startsWith('-')) {
      inputValue = `-${inputValue.slice(1).replace(/-/g, '')}`;
    }

    // Update the input value for intermediate states
    inputElement.value = inputValue;

    // Pass only valid numbers to the setter
    const numericValue = inputValue; // No need to replace commas as they are already dots
    const finalValue = Number(numericValue);
    if (!isNaN(finalValue)) {
      this.value = finalValue; // Use the setter to update valid values
    }
  }

  convertToNumber(value: number | string): number | undefined {
    // Attempt to parse as a number
    const numValue: number = Number(value);
    // Return the number if valid, otherwise return the original value
    return isNaN(numValue) ? undefined : numValue;
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  isFinding(): boolean {
    return 'value' in this.question;
  }
}
