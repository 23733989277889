import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-lightswitch',
  templateUrl: './lightswitch.component.html',
  styleUrls: ['./lightswitch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LightswitchComponent),
      multi: true,
    },
  ],
})
export class LightswitchComponent implements ControlValueAccessor {
  @Input() preLabel: boolean = false;
  @Input() label: string = '';
  @Output() changedState = new EventEmitter<boolean>();

  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;

  private onChange: (value: boolean) => void = () => {};
  private onTouched: () => void = () => {};

  constructor() {}

  change(): void {
    if (this.disabled) return;

    this.checked = !this.checked;
    this.changedState.emit(this.checked);

    this.onChange(this.checked);
    this.onTouched();
  }

  writeValue(value: boolean): void {
    this.checked = value || false;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
