import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-bubble',
  templateUrl: './status-bubble.component.html',
  styleUrls: ['./status-bubble.component.scss'],
})
export class StatusBubbleComponent {
  @Input() status = '';
  @Input() class = '';
  @Input() description = '';
}
