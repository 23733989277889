import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { DossierDetailComponent } from './views/dossier-detail/dossier-detail.component';
import { shoppingCartHasUnsavedChanges } from './building.guards';
import { FloorPlanViewerComponent } from './views/floor-plan-viewer/floor-plan-viewer.component';
import { ModelViewerComponent } from './views/model-viewer/model-viewer.component';
import { BuildingWrapperComponent } from './views/building-wrapper/building-wrapper.component';
import { InspectionOverviewComponent } from '../inspection-module/views/overview-page/inspection-overview.component';
import { InspectionDetailComponent } from '../inspection-module/views/inspection-detail/inspection-detail.component';
import { BimOverviewComponent } from './views/bim-overview/bim-overview.component';
import { BimDetailComponent } from './views/bim-detail/bim-detail.component';

const routes: Routes = [
  //This routing is only for building/id routes for the rest please other routing modules
  {
    path: 'buildings/:real_estate_building_id',
    canActivate: [AutoLoginPartialRoutesGuard],
    canDeactivate: [shoppingCartHasUnsavedChanges],
    component: BuildingWrapperComponent,
    children: [
      {
        path: 'dossier',
        component: DossierDetailComponent,
      },
      {
        path: 'model/:state',
        component: ModelViewerComponent,
      },
      {
        path: 'floor-plan/:state',
        component: FloorPlanViewerComponent,
      },
      {
        path: 'inspections',
        component: InspectionOverviewComponent,
      },
      {
        path: 'inspections/:id',
        component: InspectionDetailComponent,
      },
    ],
  },
  {
    path: 'bim',
    component: BimOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'bim/:bim_id',
    component: BimDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: 'model/:state',
        component: ModelViewerComponent,
        canActivate: [AutoLoginPartialRoutesGuard],
      },
      {
        path: 'floor-plan/:state',
        component: FloorPlanViewerComponent,
        canActivate: [AutoLoginPartialRoutesGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BuildingRoutingModule {}
