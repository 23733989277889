/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptionalGenericSubjectQueryGenericSubjectBimReference { 
    optional_GenericSubject_bim_id: string;
    optional_GenericSubject_type: OptionalGenericSubjectQueryGenericSubjectBimReference.OptionalGenericSubjectTypeEnum;
}
export namespace OptionalGenericSubjectQueryGenericSubjectBimReference {
    export type OptionalGenericSubjectTypeEnum = 'Bim';
    export const OptionalGenericSubjectTypeEnum = {
        Bim: 'Bim' as OptionalGenericSubjectTypeEnum
    };
}


