import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthInterceptor, AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { AuthorizedViewComponent } from './authorized-view/authorized-view.component';
import { RptInterceptor } from './rpt.interceptor';
import { PermissionService } from './permission-service';

const { API_BASE_PATH, AUTH_PATH, ENVIRONMENT_SPECIFIC_PROVIDERS } = environment;

// Please ignore following warning in console:
// [WARN] 0-twintopics-portal - When using silent renew and refresh tokens please set the `offline_access` scope
// This is a bug in angular-auth-oidc-client
// See: https://github.com/damienbod/angular-auth-oidc-client/issues/956
// If we set the offline_access scope, the https://auth.twinspot.com/realms/TwinTopics/protocol/openid-connect/token
// call will give CORS error

@NgModule({
  imports: [
    CommonModule,
    AuthModule.forRoot({
      config: {
        authority: `${AUTH_PATH}/realms/TwinTopics`,
        checkRedirectUrlWhenCheckingIfIsCallback: false,
        redirectUrl: `${window.location.origin}/callback`,
        unauthorizedRoute: `/unauthorized`,
        silentRenewUrl: `${window.location.origin}/silent-renew.html`,
        postLoginRoute: '/buildings',
        postLogoutRedirectUri: window.location.origin,
        clientId: 'twintopics-portal',
        scope: 'openid profile email',
        responseType: 'id_token token',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        ignoreNonceAfterRefresh: true,
        autoUserInfo: false, // if the user endpoint is not supported
        logLevel: LogLevel.Warn, // set to Debug to see what exactly goes on when renewing tokens
        secureRoutes: [API_BASE_PATH],
      },
    }),
  ],
  declarations: [AuthorizedViewComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ENVIRONMENT_SPECIFIC_PROVIDERS,
    {
      provide: RptInterceptor,
      useClass: RptInterceptor,
      deps: [HttpClient, PermissionService],
      multi: false,
    },
  ],
  exports: [AuthModule, AuthorizedViewComponent],
})
export class AuthConfigModule {}
