<div class="open-question" [class.edit]="edit" [contextMenu]="menu" [contextMenuDisabled]="!edit">
  <label for="input" class="open-question__title">
    {{ edit ? ('open-question' | translate) : question.description }}
    <span class="subject" *ngIf="!edit && question.subject.title">
      ({{ question.subject.title }})
    </span>
  </label>

  <div class="open-question__wrapper">
    <div class="open-question__input big-input">
      <input
        placeholder="{{ 'question-name' | translate }}"
        type="text"
        name="input"
        id="input"
        autocomplete="off"
        [(ngModel)]="question.description"
        *ngIf="edit" />

      <div class="open-question__additional_fields">
        <label for="subject" class="open-question__subject" *ngIf="edit">
          {{ 'subject' | translate }}
        </label>

        <input
          placeholder="{{ 'subject' | translate }}"
          type="text"
          name="input"
          id="subject"
          autocomplete="off"
          [(ngModel)]="question.subject.title"
          *ngIf="edit" />

        <input
          [type]="question.property.property_type === 'Number' ? 'number' : 'text'"
          name="input"
          autocomplete="off"
          [disabled]="disabled"
          *ngIf="!edit && !isFinding()" />
        <input
          name="input"
          [(ngModel)]="value"
          autocomplete="off"
          [disabled]="disabled"
          *ngIf="!edit && isFinding()" />

        <!--suppress JSIncompatibleTypesComparison -->
        <label
          for="units"
          class="open-question__units-title"
          *ngIf="edit && question.property.unit !== undefined && question.property.unit !== null">
          {{ 'units-title' | translate }}
        </label>

        <!--suppress JSIncompatibleTypesComparison -->
        <input
          placeholder="{{ 'units-placeholder' | translate }}"
          id="units"
          type="text"
          name="input"
          autocomplete="off"
          [(ngModel)]="question.property.unit"
          *ngIf="edit && question.property.unit !== undefined && question.property.unit !== null" />

        <!--suppress JSIncompatibleTypesComparison -->
        <span
          class="units"
          *ngIf="!edit && question.property.unit !== undefined && question.property.unit !== null">
          {{ question.property.unit }}
        </span>
      </div>
    </div>

    <app-three-dot [menu]="menu"></app-three-dot>
  </div>
</div>
