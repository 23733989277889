import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DossierService } from '@services/dossier.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersInfoService } from '@api-clients/user';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BuildingOverviewService } from '@services/building-overview.service';
import {
  BimLink,
  ConstructionReport,
  DossierDocument,
  DossierFile,
  DossierImage,
  Location,
  Note,
  TimeLineDto,
  TimeLineType,
} from '@api-clients/dossier';
import { BimService, OptionalBimSubjectQuery } from '@api-clients/bim';
import { lastValueFrom } from 'rxjs';
import { environment } from '@env/environment';
import { FormControl } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@services/user.service';
import { BlobReader, BlobWriter, ZipWriter } from '@zip.js/zip.js';
import { NotePopupService } from '../../components/note-popup/note-popup.service';
import { TimelineService } from '../../services';
import { DossierDetailService } from '../../services/dossier-detail.service';
import { Resource } from '../../../resources';
import { Scope } from '../../../scopes';
import { ReferenceType } from '@api-clients/bim/model/referenceType';

@Component({
  selector: 'app-dossier-detail',
  templateUrl: './dossier-detail.component.html',
  styleUrl: './dossier-detail.component.scss',
})
export class DossierDetailComponent {
  selectedTimelineItem: TimeLineDto | undefined;
  buildingId: string | null = '';
  //TODO : We should not have a selected item for every type of timeline
  previouslySelectedNote: Note | undefined;
  previouslySelectedConstructionReport: ConstructionReport | undefined;
  previouslySelectedLocation: string | undefined;
  previouslySelectedBimLink: BimLink | undefined;
  previouslySelectedName: string | undefined;
  buildingName: string = '';
  buildingExternalId?: string;
  selectedFile: DossierImage | DossierFile | DossierDocument | undefined;
  selectedFileUrl: string | undefined;
  breadcrumbTree = [
    { translate: 'building', link: '/buildings' },
    { translate: 'dossier-page', link: this.router.url },
  ];

  locations: Location[] = [];
  public timeLineTypes = Object.values(TimeLineType);
  @Input('control') dateTime: FormControl = new FormControl();
  protected readonly TimeLineType = TimeLineType;
  protected readonly environment = environment;
  protected readonly localStorage = localStorage;
  protected readonly Resource = Resource;
  protected readonly Scope = Scope;

  constructor(
    private readonly router: Router,
    private readonly dossierService: DossierService,
    private readonly usersInfoService: UsersInfoService,
    private readonly buildingOverviewService: BuildingOverviewService,
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService,
    private readonly userService: UserService,
    private readonly bimService: BimService,
    private readonly notePopupService: NotePopupService,
    public readonly timelineService: TimelineService,
    public readonly dossierDetailService: DossierDetailService
  ) {
    this.dossierDetailService.dossier.subscribe((dossier) => {
      this.buildingId = dossier.building_id;
      if (!this.buildingId) {
        throw new Error('No building id');
      }
      this.buildingOverviewService.getBuildingByRealEstateId(this.buildingId).subscribe((next) => {
        this.buildingName = next?.buildingMetadata?.address || '';
        this.buildingExternalId = next?.external_id;
      });
      this.timelineService.refreshTimeline();
    });

    this.dossierService.getLocations().then((locations) => {
      this.locations = locations;
    });

    this.notePopupService.noteAdded.pipe(takeUntilDestroyed()).subscribe(() => {
      this.timelineService.refreshTimeline();
    });
  }

  showNotePopup(): void {
    this.notePopupService.show();
  }

  async postNoteEvent(value: string): Promise<void> {
    const noteInputValue: string = value.trim();
    if (!noteInputValue || noteInputValue === '') return;
    await this.timelineService.publishNoteEvent(noteInputValue);
  }

  async onClickTimelineEvent(item: TimeLineDto): Promise<void> {
    this.selectedTimelineItem = item;
    this.selectedFile = undefined;

    let location: Location | undefined = undefined;

    const image_id: string = item.attachments?.images.length ? item.attachments?.images[0].id : '';
    if (image_id?.length) {
      await this.openImage(item, image_id);
    }

    switch (this.selectedTimelineItem.item_type) {
      case TimeLineType.Note:
        this.previouslySelectedNote = await this.dossierService.getNote(this.selectedTimelineItem);
        location = this.locations.find(
          (location) => location.id === this.previouslySelectedNote?.location_id
        );
        break;
      case TimeLineType.ConstructionReport:
        this.previouslySelectedConstructionReport = await this.dossierService.getConstructionReport(
          this.selectedTimelineItem
        );
        location = this.locations.find(
          (location) => location.id === this.previouslySelectedConstructionReport?.location_id
        );
        break;
      case TimeLineType.BimLink:
        this.previouslySelectedBimLink = await this.dossierService.getBimLinkForDossier(
          this.selectedTimelineItem
        );
        break;
      default:
        break;
    }

    const users = await lastValueFrom(
      this.usersInfoService.get([this.selectedTimelineItem.user_id])
    );
    this.previouslySelectedName = users[this.selectedTimelineItem.user_id];

    if (location != undefined && this.translateService.currentLang === 'en') {
      this.previouslySelectedLocation = location?.name_en;
    } else if (location != undefined) {
      this.previouslySelectedLocation = location?.name_nl;
    }
  }

  public setTimeLineType(timeLineType?: TimeLineType): void {
    if (timeLineType) {
      this.timelineService.updateFilterModel({ timeLineType });
    } else {
      this.timelineService.updateFilterModel({ timeLineType: undefined });
    }
  }

  public setFromTo(value: Date): void {
    this.timelineService.updateFilterModel({ from: value[0], until: value[1] });
  }

  public clearFromTo(): void {
    this.dateTime.setValue(null);
    this.timelineService.updateFilterModel({ from: undefined, until: undefined });
  }

  async getImageLocation(imageLocation: object): Promise<void> {
    await this.openImage(imageLocation['item'], imageLocation['imageId']);
  }

  async openImage(item: TimeLineDto, image_id: string): Promise<void> {
    this.selectedTimelineItem = item;
    this.selectedFileUrl = await this.dossierService.getImagePresignedUrl(image_id);
    this.selectedFile = await this.dossierService.getImage(image_id);
  }

  async uploadIfc(event: Event): Promise<void> {
    if (!event) return;
    const eventTarget = event.target as HTMLInputElement;
    const file = eventTarget.files?.[0];
    if (!file) throw new Error('No file selected');
    (document.getElementById('uploadButton')! as HTMLInputElement).value = '';

    let progress = 50;

    const reader = new BlobReader(file);
    const zipFileWriter = new BlobWriter();
    const zipWriter = new ZipWriter(zipFileWriter);
    await zipWriter.add(file.name, reader);
    const zipFileBlob = await zipWriter.close();

    const infoToast = this.toastr.info('Uploading IFC...', undefined, {
      extendedTimeOut: 100000000,
      timeOut: 100000000, // The progress bar is displayed only if there is a timeout, so I set a very large timeout to close it manually
      enableHtml: true,
      tapToDismiss: false,
      progressBar: true,
      progressAnimation: 'increasing',
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (<any>infoToast).toastRef.componentInstance.updateProgress = (): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (<any>infoToast).toastRef.componentInstance.width = progress;
    };

    let bimSubject: OptionalBimSubjectQuery | undefined = undefined;
    if (this.buildingId) {
      bimSubject = {
        optional_BimSubject_id: this.buildingId,
        optional_BimSubject_type: ReferenceType.RealEstateBuilding,
      };
    }

    // For now, ifc Post uses the one bag id assigned to the building via the dossier, but in the future, it should use all the ids of the cadastral parcels that the ifc occupies
    this.bimService
      .bimIfcPost(
        this.userService.organizationId,
        zipFileBlob,
        bimSubject,
        this.buildingExternalId ? [this.buildingExternalId] : undefined,
        'events',
        true
      )
      .subscribe({
        next: (event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              progress = (event.loaded / (event.total || 0)) * 100;
              break;
            case HttpEventType.Response:
              this.toastr.remove(infoToast.toastId);
              this.toastr.success('IFC file uploaded successfully', undefined, {
                positionClass: 'toast-bottom-right',
              });
              this.timelineService.publishIfcEvent('', event.body!.id);
              break;
          }
        },
        error: (error) => {
          console.error(error);
          this.toastr.remove(infoToast.toastId);
          this.toastr.error('Failed to upload IFC file', undefined, {
            positionClass: 'toast-bottom-right',
          });
        },
      });
  }

  protected getLocationById(note: Note | undefined): string {
    if (!note || !note.location_id) return '';
    const location = this.locations.find((location) => location.id === note.location_id);
    if (location) {
      return this.translateService.currentLang === 'en' ? location.name_en : location.name_nl;
    }
    return '';
  }
}
