<div
  class="boolean-question"
  [class.edit]="edit"
  [contextMenu]="menu"
  [contextMenuDisabled]="!edit">
  <label for="input" class="boolean-question__title">
    {{ edit ? ('boolean-question' | translate) : question.description }}
    <span class="subject" *ngIf="!edit && question.subject.title">
      ({{ question.subject.title }})
    </span>
  </label>

  <div class="boolean-question__wrapper">
    <div class="boolean-question__input big-input">
      <input
        placeholder="{{ 'question-name' | translate }}"
        type="text"
        name="input"
        id="input"
        autocomplete="off"
        [(ngModel)]="question.description"
        *ngIf="edit" />

      <div class="boolean-question__additional_fields">
        <label for="subject" class="boolean-question__subject" *ngIf="edit">
          {{ 'subject' | translate }}
        </label>

        <input
          placeholder="{{ 'subject' | translate }}"
          type="text"
          name="input"
          id="subject"
          autocomplete="off"
          [(ngModel)]="question.subject.title"
          *ngIf="edit" />

        <app-lightswitch *ngIf="!edit && !isFinding()" [disabled]="disabled"></app-lightswitch>

        <app-lightswitch *ngIf="!edit && isFinding()" [(ngModel)]="value" [disabled]="disabled"></app-lightswitch>
      </div>
    </div>

    <app-three-dot [menu]="menu"></app-three-dot>
  </div>
</div>
