import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Inspection, InspectionFinding, InspectionTemplateQuestion } from '@api-clients/dossier';
import { ContextMenuItem } from '@services/context.service';
import { isImagesAnswer, isInspectionFinding } from '../../../building-module/utils';
import { DossierService } from '@services/dossier.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-inspection-images-question',
  templateUrl: './images-question.component.html',
  styleUrl: './images-question.component.scss',
})
export class ImagesQuestionComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Input() disabled = false;
  @Input() inspection?: Inspection;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  @ViewChild('imageInput') protected imageInput!: ElementRef;

  expanded = false;
  menu: ContextMenuItem[] = [];

  constructor(private readonly dossierService: DossierService) {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: (): boolean => !this.first,
        action: (): void => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: (): boolean => !this.last,
        action: (): void => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => this.delete.emit(),
      },
    ];
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  protected readonly isInspectionFinding = isInspectionFinding;

  uploadImage(): void {
    this.imageInput.nativeElement.click();
  }

  async imageInputChanged(): Promise<void> {
    if (this.inspection === undefined) return;
    const dossier_id = this.inspection?.dossier_id;
    for (const file of this.imageInput.nativeElement.files) {
      const dossierImage = await this.dossierService.postImage(file, 'uploaded', dossier_id);

      const finding = this.question as InspectionFinding;
      if (finding.value === undefined || finding.value === null) {
        finding.value = { image_ids: [dossierImage.id], type: 'ImageList' };
      } else if (isImagesAnswer(finding.value)) {
        finding.value.image_ids.push(dossierImage.id);
      }
    }
  }

  get image_ids(): Array<string> {
    const finding = this.question as InspectionFinding;
    if (finding.value === undefined || finding.value === null) {
      return [];
    }
    if (isImagesAnswer(finding.value)) {
      return finding.value.image_ids;
    }
    return [];
  }

  protected readonly environment = environment;
}
