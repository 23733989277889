import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Inspection,
  InspectionFinding,
  InspectionSection,
  InspectionTemplate,
  InspectionTemplateQuestion,
  InspectionTemplateSection,
} from '@api-clients/dossier';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ContextMenuItem } from '@services/context.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Question } from '../../models';
import { isInspectionSection } from '../../../building-module/utils';

@Component({
  selector: 'app-inspection-section',
  templateUrl: './section.component.html',
  styleUrl: './section.component.scss',
  animations: [
    trigger('slideInOut', [
      state(
        'out',
        style({
          height: '*',
        })
      ),
      state(
        'in',
        style({
          height: '0',
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out')),
    ]),
  ],
})
export class SectionComponent implements OnInit {
  @Input() inspectionTemplate?: InspectionTemplate;
  @Input() section?: InspectionTemplateSection | InspectionSection;
  @Input() sectionIndex: number = 0;
  @Input() edit = false;
  @Input() disabled: boolean = false;
  @Input() inspection?: Inspection;
  @Input() active: boolean = false;

  @Output() openSection = new EventEmitter();
  @Output() deleteSection = new EventEmitter();

  protected expanded: boolean = true;
  protected menu: ContextMenuItem[] = [];

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: (): boolean => this.sectionIndex > 0,
        action: (): void => this.moveUp(),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: (): boolean => this.sectionIndex !== this.sections.length - 1,
        action: (): void => this.moveDown(),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => this.deleteSection.emit(),
      },
    ];
  }

  get sections(): InspectionSection[] | InspectionTemplateSection[] {
    return this.inspectionTemplate?.definition.sections ?? this.inspection?.content.sections ?? [];
  }

  sectionClick(event, fromArrow): void {
    if (!fromArrow && this.edit) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    this.expanded = !this.expanded;
  }

  get animationState(): string {
    //need to be derived from section info
    return this.expanded ? 'out' : 'in';
  }

  arrayMove(arr, fromIndex: number, toIndex: number): void {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  moveUp(): void {
    this.arrayMove(this.sections, this.sectionIndex, this.sectionIndex - 1);
  }

  moveDown(): void {
    this.arrayMove(this.sections, this.sectionIndex, this.sectionIndex + 1);
  }

  moveItem(movement: number, itemIndex: number): void {
    const element = this.questions[itemIndex];
    const toIndex = itemIndex + movement;
    this.questions.splice(itemIndex, 1);
    this.questions.splice(toIndex, 0, element);
  }

  itemDropped(
    event: CdkDragDrop<InspectionTemplateSection, InspectionTemplateSection, Question>
  ): void {
    if (event.previousContainer !== event.container) {
      // we dragged the item from one section to another. So we need to transfer the section item here.
      return;
    }

    if (event.previousIndex === event.currentIndex) return;
    this.arrayMove(this.questions, event.previousIndex, event.currentIndex);
  }

  deleteChild(question: InspectionTemplateQuestion): void {
    const index = this.questions.findIndex((q) => q == question);
    if (index === undefined) return;
    this.questions.splice(index, 1);
  }

  get questions(): Array<InspectionTemplateQuestion | InspectionFinding> {
    if (!this.section) return [];
    if (isInspectionSection(this.section)) {
      return this.section!.findings;
    }
    return this.section!.questions;
  }
}
