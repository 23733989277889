import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextMenuItem } from '@services/context.service';
import { InspectionPopupService } from '../../components/inspection-popup/inspection-popup.service';
import { DossierDetailService } from '../../../building-module/services/dossier-detail.service';
import {
  GenericSubjectRealEstateBuildingReference,
  InspectionList,
  InspectionSortColumn,
  InspectionsService,
  OptionalGenericSubjectQuery,
  SortOrder,
} from '@api-clients/dossier';
import { UserService } from '@services/user.service';
import { UsersInfoService } from '@api-clients/user';
import { ToastrService } from '@shared/services/toastr.service';
import { AddressableUnitDto, BuildingsService } from '@api-clients/real-estate';
import { isRealEstateBuildingIdGenericSubject } from '../../../building-module/utils';

interface InspectionListExtended extends InspectionList {
  address?: AddressableUnitDto;
}

@Component({
  selector: 'app-inspection-overview',
  templateUrl: './inspection-overview.component.html',
  styleUrls: ['./inspection-overview.component.scss'],
})
export class InspectionOverviewComponent implements OnInit {
  buildingId: string | undefined = undefined;
  dossierId?: string = undefined;
  filteredData: InspectionListExtended[] = [];
  recentInspections: InspectionList[] = [];
  defaultPageSize: number = 10;
  sortBy: InspectionSortColumn = InspectionSortColumn.TimestampUtc;
  sortOrder: SortOrder = SortOrder.Desc;
  searchText: string = '';
  activeInspection: string = '';
  menu: ContextMenuItem[] = [];
  allUsers: { [p: string]: string } = {};
  totalCount: number = 0;
  page: number = 1;
  pageSize: number = this.defaultPageSize;

  breadcrumbTree = [
    {
      translate: 'inspections-overview-page',
      link: '/forms',
    },
  ];

  columns = [
    { key: InspectionSortColumn.Description, label: 'description' },
    { key: undefined, label: 'address' },
    { key: InspectionSortColumn.TimestampUtc, label: 'date' },
    { key: InspectionSortColumn.FinishedTimeUtc, label: 'status' },
    { key: undefined, label: 'created_by' },
    { key: undefined, label: 'actions' },
  ];

  constructor(
    private readonly router: Router,
    private readonly inspectionsService: InspectionsService,
    protected readonly inspectionPopupService: InspectionPopupService,
    protected readonly dossierDetailService: DossierDetailService,
    public readonly userService: UserService,
    private readonly usersInfoService: UsersInfoService,
    private readonly toastrService: ToastrService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly buildingService: BuildingsService
  ) {
    this.buildingId = this.activatedRoute.parent?.snapshot.params['real_estate_building_id'];
    this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
    this.pageSize = this.defaultPageSize;

    this.dossierDetailService.dossier.subscribe((dossier) => {
      this.dossierId = dossier.id;
    });

    this.inspectionPopupService.hidden.subscribe(() => {
      this.reload();
    });

    this.reload();
  }

  reload(): void {
    let subject: OptionalGenericSubjectQuery | undefined = undefined;
    if (this.buildingId) {
      subject = {
        optional_GenericSubject_real_estate_building_id: this.buildingId,
        optional_GenericSubject_type: 'RealEstateBuilding',
      };
    }
    this.inspectionsService
      .inspectionGet(
        this.page,
        this.pageSize,
        this.sortBy,
        this.sortOrder,
        this.searchText,
        subject
      )
      .subscribe((inspections) => {
        if (this.page === 1) {
          this.recentInspections = inspections.items.slice(0, 4);
        }
        this.filteredData = inspections.items;
        this.totalCount = inspections.count;
        const userIds = [...new Set(this.filteredData.map((i) => i.user_id))];
        this.usersInfoService.get(userIds).subscribe((users) => {
          this.allUsers = users;
        });

        this.buildingService
          .includingAddressesGet(
            this.filteredData
              .filter((id) => id.subject && isRealEstateBuildingIdGenericSubject(id.subject))
              .map(
                (item) =>
                  (item.subject as GenericSubjectRealEstateBuildingReference)!
                    .real_estate_building_id
              )
          )
          .subscribe((buildings) => {
            for (const building of buildings) {
              const item = this.filteredData.find(
                (item) =>
                  item.subject &&
                  isRealEstateBuildingIdGenericSubject(item.subject) &&
                  item.subject.real_estate_building_id === building.id
              );
              if (item) {
                item.address = building.addresses[0];
              }
            }
          });
      });
  }

  async ngOnInit(): Promise<void> {
    this.buildMenu();
  }

  showInspectionPopup(): void {
    this.inspectionPopupService.show();
  }

  scrollDown(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'edit',
        icon: 'edit',
        action: (): void => this.navigateToInspections(this.activeInspection),
      },
      {
        name: 'finish',
        icon: 'check_small',
        action: (): void => this.finishInspection(this.activeInspection),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => this.delete(this.activeInspection),
      },
    ];
  }

  finishInspection(inspectionId: string): void {
    if (!this.dossierId) return;
    this.inspectionsService.inspectionIdGet(inspectionId).subscribe((inspection) => {
      if (inspection.finished_time_utc === null) {
        this.inspectionsService.inspectionFinishPut(inspection).subscribe(() => {
          this.toastrService.showSuccess(
            'inspection_finished_description',
            'inspection_finished_header'
          );
          this.reload();
        });
      } else {
        this.toastrService.showFailure(
          'inspection_already_finished_description',
          'inspection_already_finished_header'
        );
      }
    });
  }

  delete(inspectionId: string): void {
    this.inspectionsService.inspectionIdDelete(inspectionId).subscribe(() => {
      this.toastrService.showSuccess(
        'dossier-module.inspection.delete-success',
        'dossier-module.inspection.delete-title'
      );
      this.reload();
    });
  }

  setActive(inspectionId: string): void {
    this.activeInspection = inspectionId;
    this.buildMenu();
  }

  onPageChange(event): void {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.reload();
  }

  toggleSort(property: InspectionSortColumn | undefined): void {
    if (this.sortBy === property) {
      this.sortOrder = this.sortOrder == SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property ?? InspectionSortColumn.TimestampUtc;
      this.sortOrder = SortOrder.Desc;
    }
    this.search();
  }

  search(): void {
    this.reload();
  }

  navigateToInspections(inspectionId: string): void {
    console.warn('navigateToInspections', inspectionId, this.buildingId);
    //This eventually needs to be "go to form inside buildingId if it exists in the form, else go to form without buildingId"
    if (this.buildingId) {
      void this.router.navigate(['/buildings/' + this.buildingId + '/inspections/' + inspectionId]);
    } else {
      void this.router.navigate(['/inspections/' + inspectionId]);
    }
  }

  getOwnInspections(): InspectionList[] {
    return this.filteredData.filter((i) => i.user_id === this.userService.userId).slice(0, 4);
  }

  protected readonly SortOrder = SortOrder;
}
