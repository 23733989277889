import { Component } from '@angular/core';
import { InspectionsService, InspectionTemplateList } from '@api-clients/dossier';
import { ChooseTemplatePopupService } from './choose-template-popup.service';

@Component({
  selector: 'app-choose-template-popup',
  templateUrl: './choose-template-popup.component.html',
  styleUrl: './choose-template-popup.component.scss',
})
export class ChooseTemplatePopupComponent {
  protected selectedTemplateId?: string;
  protected active: boolean = false;

  protected inspectionTemplateList: InspectionTemplateList[] = [];

  constructor(
    private readonly chooseTemplatePopupService: ChooseTemplatePopupService,
    private readonly inspectionsService: InspectionsService
  ) {
    this.chooseTemplatePopupService.shown.subscribe(() => {
      this.inspectionsService.templateGet().subscribe((next) => {
        this.inspectionTemplateList = next;
        if (next.length > 0) {
          this.selectedTemplateId = next[0].id; // TODO: remove
        }
      });
      this.active = true;
    });

    this.chooseTemplatePopupService.hidden.subscribe(() => {
      this.active = false;
    });
  }

  close(): void {
    this.chooseTemplatePopupService.hide();
  }

  copy(): void {
    if (!this.selectedTemplateId) return;
    this.inspectionsService.templateIdGet(this.selectedTemplateId).subscribe((next) => {
      this.chooseTemplatePopupService.templateSelected(next);
      this.close();
    });
  }
}
