<div class="number-question" [class.edit]="edit" [contextMenu]="menu" [contextMenuDisabled]="!edit">
  <label for="input" class="number-question__title">
    {{ edit ? ('number-question' | translate) : question.description }}
    <span class="subject" *ngIf="!edit && question.subject.title">
      ({{ question.subject.title }})
    </span>
  </label>

  <div class="number-question__wrapper">
    <div class="number-question__input big-input">
      <input
        placeholder="{{ 'question-name' | translate }}"
        type="text"
        name="input"
        id="input"
        autocomplete="off"
        [(ngModel)]="question.description"
        *ngIf="edit" />

      <div class="number-question__additional_fields">
        <label for="subject" class="number-question__subject" *ngIf="edit">
          {{ 'subject' | translate }}
        </label>

        <input
          placeholder="{{ 'subject' | translate }}"
          type="text"
          name="input"
          id="subject"
          autocomplete="off"
          [(ngModel)]="question.subject.title"
          *ngIf="edit" />

        <input
          type="number"
          name="input"
          autocomplete="off"
          [disabled]="disabled"
          *ngIf="!edit && !isFinding()" />

        <!-- We are using type="text" for number because browsers are otherwise sanitizing
        the input and not allowing the user to input a decimal point or minus.-->
        <input
          name="input"
          [(ngModel)]="value"
          (keyup)="sanitizeInput($event)"
          type="text"
          inputmode="decimal"
          autocomplete="off"
          [disabled]="disabled"
          *ngIf="!edit && isFinding()" />

        <!--suppress JSIncompatibleTypesComparison -->
        <label
          for="units"
          class="number-question__units-title"
          *ngIf="edit && question.property.unit !== undefined && question.property.unit !== null">
          {{ 'units-title' | translate }}
        </label>

        <!--suppress JSIncompatibleTypesComparison -->
        <input
          placeholder="{{ 'units-placeholder' | translate }}"
          id="units"
          type="text"
          name="input"
          autocomplete="off"
          [(ngModel)]="question.property.unit"
          *ngIf="edit && question.property.unit !== undefined && question.property.unit !== null" />

        <!--suppress JSIncompatibleTypesComparison -->
        <span
          class="units"
          *ngIf="!edit && question.property.unit !== undefined && question.property.unit !== null">
          {{ question.property.unit }}
        </span>
      </div>
    </div>

    <app-three-dot [menu]="menu"></app-three-dot>
  </div>
</div>
