export * from './account.service';
import { AccountService } from './account.service';
export * from './assets.service';
import { AssetsService } from './assets.service';
export * from './organization.service';
import { OrganizationService } from './organization.service';
export * from './permissions.service';
import { PermissionsService } from './permissions.service';
export * from './usersInfo.service';
import { UsersInfoService } from './usersInfo.service';
export * from './whoAmI.service';
import { WhoAmIService } from './whoAmI.service';
export const APIS = [AccountService, AssetsService, OrganizationService, PermissionsService, UsersInfoService, WhoAmIService];
