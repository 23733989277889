<div class="inspection-templates">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>
      <h1 class="h4">{{ 'dossier-module.inspection-templates-page' | translate }}</h1>
    </div>

    <div class="header-bar__search">
      <app-authorized-view [permission]="Resource.InspectionTemplates" [scope]="Scope.CREATE">
        <button class="button-secondary button--has-icon" (click)="create()">
          <span class="material-symbols-rounded">add</span>
          {{ 'dossier-module.create-new-template' | translate }}
        </button>
      </app-authorized-view>
      <input
        class="top-search"
        type="search"
        [(ngModel)]="searchText"
        (input)="search()"
        placeholder="{{ 'search' | translate }}" />
    </div>
  </div>

  <h2 class="form-subtitle">{{ 'dossier-module.all-inspection-templates' | translate }}</h2>
  <app-table-filter></app-table-filter>
  <table class="table responsive-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th (click)="toggleSort(column.key)" *ngIf="column.key.length" class="sortable">
            {{ column.label | translate }}
            <span
              [class.arrow-active]="sortBy === column.key"
              [class.arrow-down]="sortOrder === 1"
              class="arrow"></span>
          </th>
          <th *ngIf="!column.key.length">
            {{ column.label | translate }}
          </th>
        </ng-container>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let template of filteredTemplates"
        [contextMenu]="menu"
        (mousedown)="setActive(template.id)">
        <td [attr.data-title]="'name' | translate" (click)="navigateToTemplate(template.id)">
          {{ template.description }}
        </td>
        <td [attr.data-title]="'status' | translate">
          {{ template.timestamp_utc }}
        </td>
        <td [attr.data-title]="'actions' | translate" class="actions">
          <app-three-dot [menu]="menu"></app-three-dot>
        </td>
      </tr>
    </tbody>
  </table>

  <app-table-pagination
    [page]="1"
    [pageSize]="defaultPageSize"
    (pageChange)="onPageChange($event)"
    [total]="(inspectionTemplates ?? []).length"></app-table-pagination>
</div>
