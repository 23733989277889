/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BimStatus } from './bimStatus';
import { BimSubject } from './bimSubject';
import { BimSource } from './bimSource';


export interface BuildingInformationModelDto { 
    id: string;
    status: BimStatus;
    source: BimSource;
    properties: any | null;
    model_3d_url?: string;
    subject?: BimSubject;
}
export namespace BuildingInformationModelDto {
}


