<div class="inspection-result" *ngIf="inspection">
  <div class="inspection-result__intro">
    <h2>{{ inspection.content.inspection_name }}</h2>
    <span *ngIf="user">{{ 'filled-on-inspection' | translate }} {{ user }}</span>
    <span *ngIf="timeStamp">
      {{ 'on-date-inspection' | translate }} {{ timeStamp | date: 'dd-MM-yyyy' }}
      {{ 'at-time-inspection' | translate }} {{ timeStamp | date: 'HH:mm' }}
    </span>
  </div>

  <div class="inspection-result__section" *ngFor="let section of inspection.content.sections">
    <h3 class="section-title">{{ section.name }}</h3>
    <div class="inspection-result__section__question" *ngFor="let finding of section.findings">
      <span class="findings">
        {{ finding.description }}
        <span *ngIf="finding.subject.title">
          (
          <i>{{ finding.subject.title }}</i>
          )
        </span>
      </span>

      <div
        class="inspection-result__section__question__images"
        *ngIf="finding.value && isImagesAnswer(finding.value)">
        <div
          class="image"
          tabindex="0"
          (keydown)="keyDown($event)"
          *ngFor="let img_id of finding.value.image_ids"
          (click)="openImage(img_id)">
          <img
            [src]="
              environment.API_BASE_PATH +
                '/dossiers/image/' +
                img_id
                | secure
                | async
            "
            alt="image" />
          <span class="material-symbols-rounded">zoom_in</span>
        </div>
      </div>
      <div
        class="inspection-result__section-question-text"
        *ngIf="finding.value && isTextAnswer(finding.value)">
        {{ finding.value.text }} {{ finding.property.unit }}
      </div>
      <div
        class="inspection-result__section-question-number"
        *ngIf="finding.value && isNumberAnswer(finding.value)">
        {{ finding.value.number }} {{ finding.property.unit }}
      </div>
      <div
        class="inspection-result__section-question-boolean"
        *ngIf="finding.value && isBooleanAnswer(finding.value)">
        <input type="checkbox" [checked]="finding.value.boolean" />
      </div>
      <div
        class="inspection-result__section-question-boolean"
        *ngIf="finding.value && isCheckListAnswer(finding.value)">
        {{ finding.value.values.join(', ') }}
      </div>
      <div
        class="inspection-result__section-question-boolean"
        *ngIf="finding.value && isRadioListAnswer(finding.value)">
        {{ finding.value.value }}
      </div>
    </div>
  </div>

  <div
    class="inspection-result-image-popup"
    [class.active]="selectedFile"
    (click)="deselectFile()"
    tabindex="0"
    (keydown)="keyDown($event)">
    <div
      class="inspection-result-image-popup__content"
      (click)="$event.stopPropagation()"
      tabindex="0"
      (keydown)="keyDown($event)">
      <app-file-specs
        [timeStamp]="(selectedFile!==undefined)? selectedFile!.timestamp_utc : ''"
        [user]="selectedFileUser"
        [fileUrl]="selectedFileUrl"
        [file]="selectedFile"></app-file-specs>
    </div>
  </div>
</div>
